import React, { useContext } from "react";
import SEO from "../../components/seo";
import CustomAuth from "../../components/custom-auth";
import Observations from "../../components/observations";
import { UserContext } from "../../provider/usercontextprovider";
import { UserTenantProvider } from "../../provider/usertenantprovider";

const ProtectedPage = () => {
  const [userContext, setUserContext] = useContext(UserContext);
  const isManager =
    userContext.username &&
    userContext.signInUserSession.accessToken.payload["cognito:groups"].includes("Manager");

  return (
    <CustomAuth>
      <SEO title="Observations" />
      <UserTenantProvider>
        {isManager ? <Observations /> : <p>User is not a manager</p> }
      </UserTenantProvider>
    </CustomAuth>
  );
}
export default ProtectedPage;
