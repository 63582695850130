import React, { useContext, useEffect, useState } from "react";
import Select from 'react-select'
import ObservationItem from "./observationItem"
import ObservationSearchForm from "./observation-search-form.js"
import * as enrollerStyles from "./enroller.module.css"
import { API, graphqlOperation } from "aws-amplify"
import { LoadMaskContext } from "../provider/loadmaskprovider"
import * as buttonStyles from './button.module.css'
import { UserTenantContext } from "../provider/usertenantprovider"
import Paging from "./paging"
import * as searchStyles from "./search.module.css"

const searchObservations = /* GraphQL */ `
  query searchObservations(
    $filter: SearchableObservationFilterInput
    $sort: SearchableObservationSortInput
    $limit: Int
    $nextToken: String
){
  searchObservations(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      email
      category
      firstName
      lastName
      productName
      result
    }
    nextToken
    total
  }
}`

const Observations = () => {

  const [userTenantContext, setUserTenantContext] = useContext(UserTenantContext)
  
  const tenantOptions = userTenantContext.filter(t => t.tenantRole.includes("Manager")).map(tenant => {
    return {
      value: tenant.tenantObj.name,
      label: tenant.tenantObj.prettyName,
    }
  });

  const [isLoading, setIsLoading] = useContext(LoadMaskContext);
  const [tenantList, setTenantList] = useContext(UserTenantContext);

  const [searchState, setSearchState] = useState({results: [],tokenStack: []})

  const [state, setState] = useState({
    observations: [],
    tenant: null
  })

  const loadObservations = async () => {
    const nextToken = searchState.currentToken;

    let filter = null;

    const startDate = searchState.startDate;
    const endDate = searchState.endDate;
    const tenant = searchState.tenant;
    const user = searchState.user;
    const resultCode = searchState.resultCode;

    filter = { and: {}};

    if(startDate != undefined || endDate != undefined) {
        filter.and.createdAt = {};

        if(startDate != undefined) {
            let startOfDay = new Date(startDate)
            startOfDay.setHours(0, 0, 0, 0)
            filter.and.createdAt.gte = startOfDay.toISOString();
        }

        if(endDate != undefined) {
            let endOfDay = new Date(endDate)
            endOfDay.setDate(endDate.getDate() + 1)
            endOfDay.setHours(0, 0, 0, 0)
            filter.and.createdAt.lte = endOfDay.toISOString();
        }
    }

    if(tenant != undefined) {
        filter.and.tenant = {};
        filter.and.tenant.eq = tenant.value;
    } else {
        filter.and.or = []
        tenantList.forEach((i) => { 
            filter.and.or.push({tenant:{eq:i.tenantId}}) 
        })
    }
    if(user != undefined && user != null && user != "") {
        filter.and.userId = {};
        filter.and.userId.eq = user
    }
    if(resultCode != undefined && resultCode != null && resultCode != "") {
        filter.and.result = {};
        filter.and.result.eq = resultCode
    }

    try {
      var result = await API.graphql(graphqlOperation(searchObservations, {filter: filter, limit: 20, nextToken: nextToken, sort: {field:"createdAt", direction:"desc"}}));

      setSearchState({
          ...searchState,
          tokenStack: [...searchState.tokenStack, result.data.searchObservations.nextToken],
          results: result.data.searchObservations.items
      });
    }catch(error){
      console.log(error)
    }finally {
      setIsLoading(false);
    }
  }

  const changeHandler = (startDate, endDate, tenant, resultCode, user, reporter) => {
    setSearchState({currentToken: null, results: [], startDate: startDate, endDate: endDate, tokenStack: [], tenant: tenant, resultCode: resultCode, user: user, reporter: reporter, id: new Date().getTime()});
  }

  useEffect(() => {
    if(searchState.startDate || searchState.endDate || searchState.tenant|| searchState.resultCode || searchState.user) {
      loadObservations()
    }
  }, [searchState.currentToken, searchState.id])

  const previousPage = () => {
    const currentToken = searchState.tokenStack[searchState.tokenStack.length - 3]
    setSearchState({
        ...searchState,
        tokenStack: searchState.tokenStack.splice(0, searchState.tokenStack.length - 2),
        currentToken: currentToken
        }
    )
  }

  const nextPage = () => {
    setSearchState({
        ...searchState,
        currentToken: searchState.tokenStack[searchState.tokenStack.length - 1]
    })
  }

  const previousEnabled = searchState.tokenStack.length > 1;
  const nextEnabled = searchState.tokenStack[searchState.tokenStack.length - 1] != null;

  return (
    <>
        <h1>Observations</h1>
        <div className={searchStyles.flex}>
            <div className={searchStyles.formWrapper}>
                <ObservationSearchForm changeHandler={changeHandler} />
            </div>
            <div className={searchStyles.resultsWrapper}>
              <div 
              className={`${searchState.results.length > 0 ? enrollerStyles.resultsList : ""}`}>
                  {searchState.results.map(r => (
                    <ObservationItem key={r.id} observation={r} />
                  ))}
              </div>
                
              <Paging onNextClick={nextPage} onPreviousClick={previousPage} previousEnabled={previousEnabled} nextEnabled={nextEnabled}/>
            </div>
        </div>
    </>
  )
}

export default Observations
