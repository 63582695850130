import React, { useContext } from "react"
import { useState } from 'react'
import * as buttonStyles from './button.module.css'
import * as searchFormStyles from './search-form.module.css'
import Select from 'react-select'
import { UserTenantContext } from "../provider/usertenantprovider"
import { subDays, format } from 'date-fns';
import DayPicker, { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
// import './day-picker.css'

const pastMonth = new Date();

const ObservationSearchForm = (props) => {
  const defaultSelected = {
    from: subDays(pastMonth, 7),
    to: pastMonth
  };
  const [showSearch, setShowSearch] = useState(true);
  const [tenant, setTenant] = useState(null);
  const [user, setUser] = useState([
    null
  ]);
  const [resultCode, setResultCode] = useState([
    null
  ]);
  const [tenantList, setTenantList] = useContext(UserTenantContext);
  const tenantOptions = tenantList.filter(t => t.tenantRole.includes("Manager")).map((tenant) => {
    return {
      value: tenant.tenantObj.name,
      label: tenant.tenantObj.prettyName
    }
  })
  const [resultCodeOptions, setResultCodeOptions] = useState([{value: "Positive", label: "Positive"}, {value: "Negative", label: "Negative"}, {value: "Invalid", label: "Invalid"}])
  const numberOfMonths = 1;
  const [range, setRange] = useState(defaultSelected);

  const toggleSearchFilter = (e) => {
    setShowSearch(!showSearch);
  }

  const callback = () => {
    if(range)
      props.changeHandler(range.from, range.to, tenant, resultCode, user);
    else
      props.changeHandler(null, null, tenant, resultCode, user);
  };
  const onTenantChange = (e) => {
    setTenant(e);
  };
  return (
    <div className={searchFormStyles.formContainer}>
      <div className={searchFormStyles.section}>
        <h2 className={searchFormStyles.formHeader} onClick={(e) => toggleSearchFilter(e.target)}>
          Search filter
          <svg className={searchFormStyles.filter} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
          </svg>
        </h2>
        <div className={searchFormStyles.formWrapper} style={{ display: showSearch == true ? 'block' : 'none' }}>
          <Select options={tenantOptions} onChange={onTenantChange} placeholder="Select Tenant Name" />
          <span className={searchFormStyles.message} style={{ display: tenant == null ? 'block' : 'none' }}>Please select a Tenant</span>
          <Select className={searchFormStyles.select} options={resultCodeOptions} placeholder="Select Result Code" onChange={(e) => setResultCode(e.value)} />
          <input className={searchFormStyles.input} name="user" type="text" placeholder="Search User" onChange={(e) => setUser(e.target.value)} />
          <div className={searchFormStyles.calendar}>
          <DayPicker
            mode="range"
            defaultMonth={pastMonth}
            selected={range}
            onSelect={setRange}
          />
          <style>
              {`
              .rdp-day_range_middle:not([disabled]), .rdp-day_range_middle:focus:not([disabled]), .rdp-day_range_middle:active:not([disabled]), .rdp-day_range_middle:hover:not([disabled]) {
                background-color: #f0f8ff;
                color:#4a90e2;
                
              }
              .rdp-day_range_start:not([disabled]), .rdp-day_range_start:not([disabled]):hover, .rdp-day_range_start:not([disabled]):focus {
                background-color:#4a90e2;
                border-width: 0px;
              }
              .rdp-day_range_end:not([disabled]), .rdp-day_range_end:not([disabled]):hover, .rdp-day_range_end:not([disabled]):focus {
                background-color:#4a90e2;
                border-width: 0px;
              }
            `}
            </style>
          </div>
          <div>
            <button className={buttonStyles.button} onClick={callback}>Search</button>
          </div>
        </div>
      </div>
    </div>
  )

}
export default ObservationSearchForm